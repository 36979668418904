body {
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  color: #3b4045;
  font: 14px/22px sans-serif, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  margin: 0px;
}

h1 {
  font-size: 44px;
  line-height: 56px;
  color: #fff;
}

h2 {
  font-size: 30px;
  line-height: 35px;
}

h3 {
  font-size: 23px;
  line-height: 25px;
}

h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
}

p {
  font-size: 16px;
  line-height: 25px;
  margin: 0px;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

a:focus,
.btn:focus,
.btn:active:focus {
  box-shadow: none;
  outline: 0 none;
}

a,
a:active,
a:focus,
a:active {
  text-decoration: none;
}

.section {
  padding: 100px 0;
}

.text-center {
  text-align: center;
}

.heading {
  text-align: center;
  color: #777777;
  padding-bottom: 70px;
}
.heading h2 {
  color: #3c3c3c;
  padding-bottom: 15px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 40px;
  line-height: 40px;
}
.heading p {
  font-family: "Droid Serif", serif;
  font-size: 15px;
  line-height: 24px;
  font-weight: 300;
}

.btn {
  border-radius: 0;
}

.btn-home {
  background-color: #1d9bf0;
  border-radius: 0px;
  color: #fff;
  font-size: 18px;
  padding: 10px 40px;
  margin-top: 40px;
}
.btn-home:hover,
.btn-home:focus {
  background-color: #1d9bf0;
  border: 1px solid #fff;
  color: #fff;
}

.bg-1 {
  background-image: url("https://static.shxqwl.com/img/1.jpg");
  background-color: #1abc9c;
  background-size: cover;
  background-attachment: fixed;
}

.bg-gray {
  background: #f8f8f8;
}

.hero-area {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-area h1 {
  color: #fff;
  font-size: 60px;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 60px;
  margin-top: 30px;
}
.hero-area p {
  color: #fff;
  font-size: 20px;
  line-height: 1.3;
}

footer {
  text-align: center;
  background: #272727;
  padding: 27px 0px;
}
footer p,
footer a {
  font-weight: 300;
  font-size: 12px;
  color: #dfdfdf;
  letter-spacing: 3px;
}
footer p a {
  color: #fff;
}
footer p a:hover {
  color: #fff;
  text-decoration: none;
}
